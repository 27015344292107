import { expand, Player } from "@blings/blings-player";
import "./DemoWindow.scss";
import { debounce } from "lodash";
import { VidPart } from "../../API";
import { getENV } from "../../stores/consts";
import PlayerManager from "../../helpers/playerManager";
let lastCreationTimestamp = 0;
const destroyPrevPlayer = async (p: any) => {
  try {
    await p.destroy();
    p = null;
  } catch (e) {
    console.error("err destroying player", e);
  }
};
const createDemoPlayer = async (
  setLoaderDisplay,
  scenes,
  project,
  liveControlData,
  data,
  autoplay,
  posterFrame
) => {
  lastCreationTimestamp = Date.now();
  const thisCreationTimestamp = lastCreationTimestamp;
  let p = (window as any).dp;
  if (!p || p.assetsContainer) {
    const container = document.getElementById("demo-container");
    setLoaderDisplay(true);
    if (!container) {
      return;
    }
    if (p) {
      await destroyPrevPlayer(p);
    }
    const videoParts = await fillProjectVideoParts(project.videoParts, scenes);
    videoParts.forEach((vp) => {
      vp.modsArr = vp.modsArr?.map((mod) => JSON.parse(mod.dataStr));
    });
    // As the function is asyncronous it can be called again before the it has created the previous player, so check if the current creation call is the latest
    if (thisCreationTimestamp === lastCreationTimestamp && scenes.length)
      try {
        const playerParams: any = {
          data: data,
          settings: {
            container: container,
            autoplay: autoplay,
            posterFrame,
            maxLoadingTime: 10000,
          },
          scenes,
        };
        // We need to check for the already existing player version in use.
        let playerVersion: any = PlayerManager.get().GetCurrentMajorVersion();
        if (playerVersion === "3") {
          playerParams.project = {
            //@ts-ignore
            videoParts,
            liveControlData,
          };
        } else {
          playerParams.project = {
            env: getENV(),
            id: project.id,
            videoParts,
            liveControlData,
          };
          // If the player version is setted to latest, we should use the latest player version.
          playerVersion = -1;
        }

        let player: Player = await PlayerManager.get().createPlayer(
          playerParams,
          { playerMajorVersion: playerVersion, newPlayer: true }
        );
        setLoaderDisplay(false);
        let dp = (window as any).dp;
        if (dp) {
          destroyPrevPlayer(dp);
        }
        resizePlayer(player);
        (window as any).dp = player;
        window.onresize = () => {
          resizePlayer((window as any).dp);
        };

        /*
        BlingsPlayer.create({
          //@ts-ignore
          project: { env: getENV(), id: project.id, videoParts, liveControlData },
          data: data,
          settings: {
            container: container,
            autoplay: autoplay,
            posterFrame,
            maxLoadingTime: 10000,
          },
          scenes,
        }).then((p) => {
          setLoaderDisplay(false);
          let dp = (window as any).dp;
          if (dp) {
            destroyPrevPlayer(dp);
          }
          resizePlayer(p);
          (window as any).dp = p;
          window.onresize = () => {
            resizePlayer((window as any).dp);
          };
        });
        */
      } catch (e) {
        console.error("err", e);
      }
  } else {
    createDemoPlayerDebounced(
      setLoaderDisplay,
      scenes,
      project,
      liveControlData,
      data,
      autoplay,
      posterFrame
    );
  }
};

const resizePlayer = (p: Player) => {
  const maxHeight = window.innerHeight * 0.7;
  let widthPrecent = 100;
  if (p.animationData.h > maxHeight) {
    widthPrecent = (maxHeight / p.animationData.h) * 100;
  }
  document
    .getElementsByClassName("demo-container-wrapper")[0]
    .setAttribute("style", "max-width:" + widthPrecent + "%; padding:1%");
};
export const createDemoPlayerDebounced = debounce(createDemoPlayer, 1500, {
  trailing: true,
  leading: false,
});

async function fillProjectVideoParts(videoParts: VidPart[], scenes: string[]) {
  const videoPartsCopy: Array<VidPart> = JSON.parse(JSON.stringify(videoParts));
  const used: VidPart[] = [];
  const notUsed: VidPart[] = [];
  const scenesSet = new Set(scenes);
  videoPartsCopy.forEach((vp: VidPart) => {
    scenesSet.has(vp.name) ? used.push(vp) : notUsed.push(vp);
  });

  return [...(await fillVideoParts(used)), ...notUsed];
}
const jsonCache = {};
export async function fillVideoParts(videoParts: Array<VidPart> | null) {
  if (!videoParts) return [];
  let jsonsRawP: Array<Promise<Response | any>>;

  jsonsRawP = videoParts.map(
    (vp: VidPart) => jsonCache[vp.jsonUrl] || fetch(vp.jsonUrl)
  );

  const jsonsRaw = await Promise.all(jsonsRawP);
  const jsons = (
    await Promise.all(
      jsonsRaw.map((raw) => {
        if (raw.json) {
          return expand(raw.json());
        } else {
          return raw;
        }
      })
    )
  ).map((json) => expand(json));
  videoParts.forEach((vp, i) => {
    jsonCache[vp.jsonUrl] = jsons[i];
  });

  return jsons.map((json, i) => ({
    json,
    modsArr: videoParts[i].modsArr || [],
    name: videoParts[i].name || "",
    fonts: videoParts[i].fonts || [],
  }));
}
