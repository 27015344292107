import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { cssRootVars } from "./theme";
import * as Sentry from "@sentry/react"; // Error tracking and reporting lib
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn:
    "https://d522b86e6e804d76b19216e989b5dae2@o1306614.ingest.sentry.io/4503919632580608",
  integrations: [new BrowserTracing()],
  environment: process.env.NODE_ENV,
  // This represents the change that any given transaction will be sent to Sentry
  // For the production environment, is recomended to have a lower value, like ~0.1
  tracesSampleRate: process.env.NODE_ENV === "development" ? 1.0 : 0.1,
});

const themeStyle = document.createElement("style");
(window as any).themeS = themeStyle;
themeStyle.innerHTML = cssRootVars;
document.body.append(themeStyle);

const ROOT = document.getElementById("root");

ReactDOM.render(<App />, ROOT);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
