import React from "react";
import { useMst } from "../../stores/main";
import { observer } from "mobx-react-lite";
import { Tooltip } from "antd";
import { TimeIcon } from "../../icons-and-animations/Icons";

export const PublishStatus = observer(() => {
  const {
    platformStore: {
      selectedProjectHasLiveVersion,
      selectedProjectBasicInfo,
      projectWorkspaceVersion,
      selectedProjectIsLatestVersionPublished,
    },
    dynamicDataStore: { hasUnsavedChanges: hasUnsavedDynamicDataChanges },
    modsStore: { hasUnsavedChanges: hasUnsavedModsChanges },
  } = useMst();
  const hasUnsavedChanges =
    hasUnsavedModsChanges || hasUnsavedDynamicDataChanges;
  // This is the case where the project was published and then the user made changes
  if (selectedProjectHasLiveVersion) {
    if (
      projectWorkspaceVersion?.updatedAt &&
      !selectedProjectIsLatestVersionPublished
    ) {
      const editText = savedDate2Relative(projectWorkspaceVersion.updatedAt);
      const savedDate = new Date(projectWorkspaceVersion?.updatedAt);
      return (
        <Tooltip
          overlayStyle={{ width: "100%" }}
          title={`Last edit was ${editText}`}
          placement="left"
        >
          <div style={{ display: "flex" }}>
            <TimeIcon />
          </div>
        </Tooltip>
      );
    }
  }
  return null;
});

export function GetPublishedTime(
  selectedProjectHasLiveVersion: string,
  selectedProjectBasicInfo: string
) {
  // This is the case where the project was published and then the user made changes
  if (selectedProjectHasLiveVersion) {
    // @ts-ignore This is a bug in the linting. This is checked in the get property
    const editText = savedDate2Relative(selectedProjectBasicInfo?.publishedAt || selectedProjectBasicInfo?.updatedAt);
    return `Published version.
    ${editText}`;
  }
  // else {
  //   if (projectWorkspaceVersion?.updatedAt) {
  //     const editText = savedDate2Relative(projectWorkspaceVersion.updatedAt);
  //     const savedDate = new Date(projectWorkspaceVersion?.updatedAt);
  //     return `Last edit was ${editText}`}
  //   }
  return null;
}

export function savedDate2Relative(date: string) {
  const TO_SECONDS = 1000;
  const TO_MINUTES = TO_SECONDS * 60;
  const TO_HOURS = TO_MINUTES * 60;
  const TO_DAYS = TO_HOURS * 24;
  const TO_WEEKS = TO_DAYS * 7;

  const now = Date.now();
  const savedAt = Date.parse(date);
  const diff = now - savedAt;
  const savedDate = new Date(date);

  let editText = `${savedDate.toLocaleDateString("default", {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  })}, ${savedDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  })} `;

  if (diff / TO_WEEKS > 1) {
    editText += `(${Math.floor(diff / TO_WEEKS)} weeks ago)`;
  } else if (diff / TO_DAYS > 1) {
    const days = Math.floor(diff / TO_DAYS);
    editText += `(${days} ${days === 1 ? "day" : "days"} ago)`;
  } else if (diff / TO_HOURS > 1) {
    const hours = Math.floor(diff / TO_HOURS);
    editText += `(${hours} ${hours === 1 ? "hour" : "hours"} ago)`;
  } else if (diff / TO_MINUTES > 1) {
    const minutes = Math.floor(diff / TO_MINUTES);
    editText += `(${minutes} ${minutes === 1 ? "minute" : "minutes"} ago)`;
  } else {
    editText += "(seconds ago)";
  }
  return editText;
}
