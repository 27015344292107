import { observer } from "mobx-react-lite";
import { Def } from "../mod-definitions";
import { Col, Row, Space, Typography } from "antd";
import React, { useRef } from "react";
import { isDisabledModButton } from "./NewModButtonsList";
import { NewModButtonsListButtonParent } from "./NewModButtonsListButtonParent";
import { HoverableLottie } from "../../../BaseComps/HoverableLottie";
import { DownOutlined } from "@ant-design/icons";

export const ButtonRender = observer(
  ({
    color,
    animation,
    disabledDef,
    text,
    changeDef,
    playerStore,
    selectedProject,
    modsStore,
    isInDropdown,
  }: {
    color: string;
    animation: any;
    disabledDef: Def<any>["disabled"];
    text: string;
    changeDef?: Def<any>;
    playerStore;
    selectedProject;
    modsStore;
    isInDropdown?: boolean;
  }) => {
    const disabled = isDisabledModButton(
      playerStore,
      disabledDef,
      selectedProject
    );

    const modBtnRef = useRef<HTMLDivElement>(null);

    return (
      <div className={"NewModButtonRender"}>
        <NewModButtonsListButtonParent
          id={`add_connector_${text.toLowerCase()}`}
          reactRef={modBtnRef}
          color={color}
          disable={disabled}
          onClick={(...args) => {
            if (changeDef) {
              modsStore.addMod(changeDef.change);
            }
          }}
        >
          <Space>
            <div className="modIcon">
              {animation && (
                <HoverableLottie
                  hoverableElement={modBtnRef}
                  animationData={animation}
                  size={55}
                />
              )}
              {/*<Icon style={{ color: color, opacity: disabled ? 0.5 : 1 }} />*/}
            </div>
          </Space>
        </NewModButtonsListButtonParent>
        <Typography.Text>
          <Col>
            <Row align="middle" style={{ fontSize: "80%" }}>
              {text}
            </Row>{" "}
            {isInDropdown && (
              <Row>
                <DownOutlined
                  style={{
                    margin: "auto",
                    marginTop: "5px",
                    transform: "scaleY(0.7)",
                  }}
                />
              </Row>
            )}
          </Col>
        </Typography.Text>
      </div>
    );
  }
);
