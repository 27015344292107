import { Col, Input, Row, Select, Space, Switch } from "antd";
import { IInteractiveModForm } from "@blings/blings-player";
import { ModsPanelCompProps } from "../ModsPanel";
import { useMst } from "../../../stores/main";
import { JsEditor } from "../../Editor/JsEditor";
import { LayerSelector } from "../../LayerSelector";
import Text from "antd/lib/typography/Text";
import MainCTAButton from "../../MainCTA/MainCTAButton";
import { ExperimentOptimizationTechnique } from "../../../API";

export const DEFAULT_FUNCTION_FOR_EXPRESSION = (
  isControlDeprecated: boolean
) => `(inputs, sessionId, userId, data${
  !isControlDeprecated ? ", liveControlData" : ""
}) => {
  fetch("ENDPOINT", {
    method: "POST",
    body: JSON.stringify({
      sessionId,
      userId,
      data: inputs
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
}`;
export function InputFormForm(props: ModsPanelCompProps<IInteractiveModForm>) {
  const {
    change: { inputKeys },
  } = props;
  const { change } = props;
  const {
    platformStore: { inputNames, isControlDeprecated },
    experimentStore: { selectedExperimentOptimizationTechnique },

  } = useMst();
  const hasVariantsEnabled =
  selectedExperimentOptimizationTechnique &&
  selectedExperimentOptimizationTechnique !==
  ExperimentOptimizationTechnique.DISABLED;


  const inputNameOption = inputNames.filter(
    (name, index, self) => self.indexOf(name) === index
  ).map((inputName) => (
    <Select.Option key={inputName}>{inputName}</Select.Option>
  ));

  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <Row>
        <Col>
          <Text type="secondary">Give this button a name [Optional]</Text>
          {/* <InfoTooltip info="" /> */}
          <div style={{ display: "flex", alignItems: "center", gap: "25px" }}>
            <Input
              size={"large"}
              id="connector-cta-name"
              value={props.change.ctaName}
              addonBefore={"Name"}
              // eslint-disable-next-line no-template-curly-in-string
              placeholder="Example: Use the text on the button"
              onChange={(e) =>
                props.onChange(props.index, ["ctaName"], [e.target.value])
              }
              allowClear={true}
            />
          </div>
        </Col>
      </Row>

      <span style={{ color: "lightgray" }}>
        Gather the fields you want to save to the data base using the below.
      </span>
      <Col>
        <span>The inputs fields you want to save:</span>
        <Select
          placeholder="Choose inputs"
          mode="multiple"
          value={inputKeys}
          size="large"
          onChange={(e) => {
            props.onChange(props.index, "inputKeys", e);
          }}
          allowClear
          style={{ width: "100%" }}
        >
          {inputNameOption}
        </Select>
      </Col>
      <Col>
        <span>The layers that are part of the 'Submit' button:</span>
        <LayerSelector
          isMulti={true}
          assetLayerPairs={props.assetLayerPairs}
          selectedAssetId={change.assetId}
          selectedLayerName={change.layerName}
          selectedLayerUid={change.layerUid}
          onChange={props.onLayerChange}
          selectedAdditionals={change.additionals}
          index={props.index}
          jsonVid={props.json}
        />
      </Col>
      <Row align="middle">
        <span>Send to Blings</span>
        <Switch
          checked={change.sendToThirdParty}
          onChange={(e) => props.onChange(props.index, "sendToThirdParty", e)}
        />
        <span>Customize your own</span>
      </Row>
      {change.sendToThirdParty ? (
        <JsEditor
          defaultValue={
            change.functionString ||
            DEFAULT_FUNCTION_FOR_EXPRESSION(isControlDeprecated)
          }
          onChange={(e) => props.onChange(props.index, "functionString", e)}
        />
      ) : null}
      {
        hasVariantsEnabled && (
          <MainCTAButton
            change={props.change}
            onChange={props.onChange}
            index={props.index}
          />
        )
      }
    </Space>
  );
}
