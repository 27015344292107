import { useEffect, useState } from "react";
import { InputNumber, Select, Space } from "antd";
import { ModsPanelCompProps } from "../ModsPanel";
import { DynamicValueMod, DynamicValueModTypes } from "../DynamicValueMod";
import {
  ITextChangeMod,
  findLayersOrLayerAssets,
  getPath,
  COMMON_PATHS,
} from "@blings/blings-player";
import { InfoTooltip } from "../../../BaseComps/InfoTooltip";
import { LayerSelector } from "../../LayerSelector";
import { rootStore } from "../../../stores/main";
import { ExperimentOptimizationTechnique } from "../../../API";

const { Option } = Select;
export function TextChangeForm(props: ModsPanelCompProps<ITextChangeMod>) {
  const { change, json } = props;
  const [hasPointText, setHasPointText] = useState(false);
  const [valueSetterType, setValueSetterType] = useState("dataKey");

  useEffect(() => {
    if (change.layerName && change.layerUid) {
      const layers = findLayersOrLayerAssets({
        assetId: change.assetId,
        layerName: change.layerName,
        layerUid: change.layerUid,
        jsonVid: json,
        additionals: change.additionals,
      });
      const hasPointText = layers?.some(
        (layer) => !getPath(layer, COMMON_PATHS.PARAGRAPH_IN_LAYER)
      );
      setHasPointText(!!hasPointText);
    }
  }, [
    change.layerName,
    change.assetId,
    change.layerUid,
    change.additionals?.length,
  ]);
  // set fontSize
  useEffect(() => {
    if (!change.fontSize || valueSetterType !== "value") {
      // Get font size from json
      const layers = findLayersOrLayerAssets({
        assetId: change["assetId"],
        layerName: change["layerName"],
        layerUid: change["layerUid"],
        jsonVid: json,
      });
      if (layers?.length) {
        const layer = layers[0];
        const fontSize: string = getPath(
          layer,
          COMMON_PATHS.FONTSIZE_IN_TEXT_LAYER
        ); // If the layer has text
        props.onChange(props.index, "fontSize", fontSize);
      }
    }
  }, [valueSetterType, change.value]);

  useEffect(() => {
    if (valueSetterType === "value" && /\$\{.*?\}/.test(change?.value || "")) {
      change.fontSize && props.onChange(props.index, "fontSize", undefined);
    }
    if (valueSetterType === "value" && !/\$\{.*?\}/.test(change?.value || "")) {
      change.fontSize && props.onChange(props.index, "maxChars", undefined);
    }
  }, [change.value, change.fontSize, valueSetterType]);

  const hasExperimentsEnabled =
    rootStore.experimentStore.selectedExperimentOptimizationTechnique &&
    rootStore.experimentStore.selectedExperimentOptimizationTechnique !==
      ExperimentOptimizationTechnique.DISABLED;
  const experimentId = change.experimentId as string;
  const experimentData =
    experimentId !== undefined
      ? rootStore.experimentStore.getExperiment(experimentId)
      : undefined;
  const experimentVariants =
    experimentId !== undefined ? experimentData?.variants : undefined;
  const hasExperiment =
    experimentId !== undefined &&
    experimentVariants &&
    experimentVariants.length >= 2;

  enum CaseAdjustments {
    NoChange = "",
    Uppercase = "uppercase",
    Lowercase = "lowercase",
    Titlecase = "titlecase",
    Sentencecase = "sentencecase",
  }
  const getExplanation = (value: CaseAdjustments) => {
    switch (value) {
      case CaseAdjustments.Sentencecase:
        return "Capitalizes first letter of a sentence";
      case CaseAdjustments.Titlecase:
        return "Capitalizes First Letter In Each Word";
      case CaseAdjustments.Lowercase:
        return "converts text to lowercase";
      case CaseAdjustments.Uppercase:
        return "CONVERTS TEXT TO UPPERCASE";
      case CaseAdjustments.NoChange:
      default:
        return "No change";
    }
  };
  const verticalCenteringInput = hasPointText ? (
    <span>
      Vertical Alignment{"  "}
      <InfoTooltip
        info={
          "Vertically centers the text in the bounding box of the text layer. Only for point text"
        }
      />{" "}
      <span style={{ marginLeft: "0.7rem" }}>
        <Select
          value={change.verticalAlign || "bottom"}
          style={{ width: 120 }}
          onChange={(value) => {
            props.onChange(props.index, "verticalAlign", value);
          }}
        >
          <Option value="top">Top</Option>
          <Option value="center">Center</Option>
          <Option value="bottom">Bottom</Option>
        </Select>
      </span>
    </span>
  ) : null;

  const fontSizeOrBreakPointInput = () => {
    const isDynamic =
      ["dataKey", "expression", "liveControlKey", "inputName"].includes(
        valueSetterType
      ) ||
      (valueSetterType === "value" && /\$\{.*?\}/.test(change.value || "")) ||
      (hasExperimentsEnabled && hasExperiment);
    const title = isDynamic ? "Font Size Breakpoint " : "Font-size ";
    const infoTooltipText = isDynamic
      ? "Dynamic text with more characters than this number, will start shrinking down to smartly fit the design"
      : "Font size of the text in pixels";
    return (
      <>
        <span>
          {title} <InfoTooltip info={infoTooltipText} />{" "}
          <InputNumber
            className={"breakpoint-input"}
            min={1}
            max={800}
            value={isDynamic ? change.maxChars : change.fontSize}
            onChange={(value) =>
              value &&
              props.onChange(
                props.index,
                ["maxChars", "fontSize"],
                isDynamic ? [value, undefined] : [undefined, value]
              )
            }
          />
        </span>
        {valueSetterType === "value" && /\$\{.*?\}/.test(change.value || "") && (
          <span style={{ opacity: "0.5" }}>
            Font-size{" "}
            <InfoTooltip
              info={
                "Font size of the text in pixels. Available to edit if text is not dynamic"
              }
            />{" "}
            <InputNumber
              className={"breakpoint-input"}
              min={1}
              max={800}
              disabled={true}
              value={change.fontSize}
            />
          </span>
        )}
      </>
    );
  };
  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <span>
        <LayerSelector
          isMulti={true}
          assetLayerPairs={props.assetLayerPairs}
          selectedAssetId={change.assetId}
          selectedLayerName={change.layerName}
          selectedLayerUid={change.layerUid}
          selectedAdditionals={change.additionals}
          onChange={props.onLayerChange}
          jsonVid={json}
          index={props.index}
          mapFilterLayerFunc={(
            p,
            layerName,
            assetId,
            layerUid,
            additionals
          ) => {
            const layers = findLayersOrLayerAssets({
              assetId: assetId === "main" ? undefined : assetId,
              layerName,
              jsonVid: json,
              layerUid,
              additionals,
            });
            let parent;
            let child;
            const text =
              layers?.length && getPath(layers[0], COMMON_PATHS.TEXT_IN_LAYER);
            if (!!text) {
              if (assetId !== "main") {
                parent = assetId;
                child = layerName;
              } else {
                parent = layerName;
              }
              p.push(
                text === layerName
                  ? {
                      renderText: `${text} - ${assetId}`,
                      layerUid,
                      layerName,
                      assetId,
                      parent,
                      child,
                    }
                  : {
                      renderText: `${text} [${layerName} - ${assetId}]`,
                      layerUid,
                      layerName,
                      assetId,
                      parent,
                      child,
                    }
              );
            }
            return p;
          }}
        />
      </span>
      <div>
        <DynamicValueMod
          change={props.change}
          onChange={props.onChange}
          index={props.index}
          type={DynamicValueModTypes.textarea}
          jsonVid={json}
          onChangeType={setValueSetterType}
        />
      </div>
      {fontSizeOrBreakPointInput()}
      {verticalCenteringInput}
      <span>
        Case Adjustment{"  "}
        <InfoTooltip
          info={"Easily transform text using various case options"}
        />{" "}
        {"  "}
        <Select
          value={props.change.caseAdjustment || "No change"}
          style={{ width: 220 }}
          optionLabelProp="label"
          onChange={(value) => {
            props.onChange(props.index, "caseAdjustment", value ? value : "");
          }}
        >
          {Object.values(CaseAdjustments).map((value) => {
            const explanation = getExplanation(value);
            const displayValue = value
              ? value.charAt(0).toUpperCase() + value.slice(1)
              : "No change";

            return (
              <Option key={value} value={value} label={displayValue}>
                <div>
                  <p>{displayValue}</p>
                  <p
                    style={{
                      fontSize: "11px",
                      color: "#cccccc",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {explanation}
                  </p>
                </div>
              </Option>
            );
          })}
        </Select>
      </span>
    </Space>
  );
}
