import {
  gradientToMultipleHex,
  rgbaArrToHexStr,
} from "../../../helpers/colors";
import { Space } from "antd";
import { IThemeColorChangeMod } from "@blings/blings-player";

function ColorBox({ clr, showAlpha}: { clr: string | ColorArr, showAlpha?: boolean}) {
  const title = typeof clr === "string" ? clr : rgbaArrToHexStr(clr, showAlpha ? true : false);
  let bg = typeof clr === "string" ? clr : rgbaArrToHexStr(clr, showAlpha ? true : false);

  if(!showAlpha){
    bg = bg.slice(0, 7);
  }
  return (
    <div
      title={title}
      style={{
        width: "0.6rem",
        height: "0.6rem",
        backgroundColor: bg,
        borderRadius: "50%",
        border: "1px solid black",
      }}
    />
  );
}

export function getColorTitles(c: IThemeColorChangeMod, t: string) {
  const maxDisplayedColors = 3;

  // Transform the colors into hex strings
  const hexColors = c.froms.map((f) => {
    if (typeof f === "string") {
      return f;
    } else {
      if (f.length >= 12) {
        const gradient = f.slice(0, f.length - 1) as ColorArr;
        const stop = f[f.length - 1] as number;

        // Get all the colors that are part of the gradient
        const multipleHex = gradientToMultipleHex(gradient, true);

        const selectedIndex = Math.floor((stop - 1) / 4);
        return multipleHex[selectedIndex];
      }
      return rgbaArrToHexStr(f);
    }
  });

  // Remove duplicates
  const colors = hexColors.filter(
    (v, i, a) =>
      a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
  );

  return (
    <Space>
      {colors.slice(0, maxDisplayedColors).map((f, i) => (
        <ColorBox key={i} clr={f} />
      ))}
      {c.froms.length > maxDisplayedColors
        ? `(+${c.froms.length - maxDisplayedColors})`
        : undefined}
      {"->"}
      {c.value ? <ColorBox clr={t} /> : t}
    </Space>
  );
}
