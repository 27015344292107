import {
  COMMON_PATHS,
  findLayersOrLayerAssets,
  getPath,
  IInteractiveModInput,
} from "@blings/blings-player";
import { ModsPanelCompProps } from "../../ModsPanel";
import { Input, Row, Space, Switch } from "antd";
import { LayerSelector } from "../../../LayerSelector";
import { InfoTooltip } from "../../../../BaseComps/InfoTooltip";

export function AddInteractiveInputForm(
  props: ModsPanelCompProps<IInteractiveModInput>
) {
  const { change, json } = props;
  return (
    <Space direction={"vertical"} style={{ width: "100%" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "25px" }}>
        <span>
          Select a name for the input field:
          <InfoTooltip
            style={{ display: "inline-block" }}
            info={"In order to access to the input value, use: `inputs[name]`"}
          />
        </span>
      </div>
      <Input
        size="large"
        value={change.value}
        addonBefore="Name"
        // The index of the mod, the property name, the value to update
        onChange={(e) => props.onChange(props.index, "value", e.target.value)}
        allowClear={true}
      />
      <span>
        Set the placeholder message:
        <InfoTooltip
          style={{ display: "inline-block" }}
          info={
            "This text will be shown in the input field and will disappear when the user starts typing"
          }
        />
      </span>
      <Input
        size="large"
        value={change.placeholder}
        addonBefore="Placeholder"
        onChange={(e) =>
          props.onChange(props.index, "placeholder", e.target.value)
        }
        allowClear={true}
      />
      <span>
        Select a layer that will become an input field:
        <InfoTooltip
          style={{ display: "inline-block" }}
          info={
            "Input fields can be created only from paragraph text layers in Adobe After Effects"
          }
        />
      </span>
      <LayerSelector
        isMulti={false}
        placeholder="Select layer that will become input field"
        assetLayerPairs={props.assetLayerPairs}
        selectedAssetId={change.assetId}
        selectedLayerName={change.layerName}
        selectedLayerUid={change.layerUid}
        onChange={props.onLayerChange}
        index={props.index}
        jsonVid={json}
        mapFilterLayerFunc={(p, layerName, assetId, layerUid, additionals) => {
          const layers = findLayersOrLayerAssets({
            assetId: assetId === "main" ? undefined : assetId,
            layerName,
            jsonVid: json,
            layerUid,
            additionals,
          });
          let parent;
          let child;
          const text =
            layers?.length && getPath(layers[0], COMMON_PATHS.TEXT_IN_LAYER);
          const isParagraph =
            layers?.length &&
            getPath(layers[0], COMMON_PATHS.PARAGRAPH_IN_LAYER);
          if (text && isParagraph) {
            if (assetId !== "main") {
              parent = assetId;
              child = layerName;
            } else {
              parent = layerName;
            }
            p.push(
              text === layerName
                ? {
                  renderText: `${text} - ${assetId}`,
                  layerUid,
                  layerName,
                  parent,
                  child,
                }
                : {
                  renderText: `${text} [${layerName} - ${assetId}]`,
                  layerName,
                  layerUid,
                  parent,
                  child,
                }
            );
          }
          return p;
        }}
      />
      <Row align="middle">
        <span>Short answer</span>
        <Switch
          checked={change.isMultiline || false}
          onChange={(e) => props.onChange(props.index, "isMultiline", e)}
        />
        <span>Multiline</span>
      </Row>
    </Space>
  );
}
