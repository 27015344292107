import * as React from "react";
import { Modal, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import { MSTContext } from "../../stores/main";
import { useHistory } from "react-router-dom";
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, rectSwappingStrategy } from "@dnd-kit/sortable";
import { BlingsBtn } from "../antd-extensions/blings-btn.component";
import { AsyncOpState } from "../../stores/async-op-state";
import { VidPart } from "../../API";
import MenuTag from "./MenuTag";
type Props = {};

export const VideoPartsSelection = observer((props: Props) => {
  const {
    platformStore: {
      projectWorkspaceVersion,
      setVideoParts,
      selectedProjectId,
      selectedVideoPartName,
      deleteVideoPart,
      renameVideoPart,
      duplicateScene
    },
    modsStore: { hasUnsavedChanges },
  } = useContext(MSTContext);
  const [modalUnsavedChanges, setModalUnsavedChanges] = React.useState("");
  const [allVideoPartsNames, setAllVideoPartsNames] = React.useState<string[]>([]);
  const history = useHistory();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    // Check if 'over' is not null before accessing its properties
    if (over && active.id !== over.id) {
      // Add your rearrange logic here
      const items = Array.from(
        projectWorkspaceVersion?.videoParts || []
      ) as Array<VidPart>;
      const activeItemIndex = items.findIndex(
        (item) => item.name === active.id
      );
      const overItemIndex = items.findIndex((item) => item.name === over.id);
      const [reorderedItem] = items.splice(activeItemIndex, 1);
      items.splice(overItemIndex, 0, reorderedItem);
      setVideoParts(items);
    }
  };

  useEffect(() => {
    setAllVideoPartsNames(
      projectWorkspaceVersion?.videoParts?.map((vp) => vp.name) || []
    );
  }, [projectWorkspaceVersion?.videoParts]);
  return (
    <>
      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <div className={"video-parts"}>
          <Typography.Title style={{ fontSize: "12px" }}>
            Scenes
          </Typography.Title>
          <div className="vid-part-tags-container">
            <SortableContext
              items={
                projectWorkspaceVersion?.videoParts?.map((vp) => vp.name) || []
              }
              strategy={rectSwappingStrategy}
            >
              {projectWorkspaceVersion?.videoParts &&
                projectWorkspaceVersion.videoParts.map((vp, index) => (
                  <MenuTag
                    vp={vp}
                    index={index}
                    key={vp.name}
                    selectedProjectId={selectedProjectId}
                    selectedVideoPartName={selectedVideoPartName}
                    deleteVideoPart={deleteVideoPart}
                    handleClick={() => {
                      if (hasUnsavedChanges) {
                        setModalUnsavedChanges(vp.name);
                      } else {
                        history.push(`/${selectedProjectId}/${vp.name}`);
                      }
                    }} renameVideoPart={renameVideoPart} duplicateVideoPart={duplicateScene} allVideoPartsNames={allVideoPartsNames}/>
                ))}
            </SortableContext>
          </div>
        </div>
      </DndContext>
      <Modal
        open={!!modalUnsavedChanges}
        onCancel={() => {
          setModalUnsavedChanges("");
        }}
        closable={false}
        footer={[
          <div
            style={{
              display: "flex",
              gap: "15px",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <button
              key="ok"
              type="button"
              onClick={() => {
                history.push(`/${selectedProjectId}/${modalUnsavedChanges}`);
                setModalUnsavedChanges("");
              }}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                background: "none",
                border: "none",
              }}
            >
              Proceed without saving
            </button>
            <BlingsBtn
              key="publish"
              className={"publish"}
              opState={AsyncOpState.Changed}
              htmlType={"submit"}
              btnTexts={{
                [AsyncOpState.Changed]: "Cancel",
              }}
              onClick={() => setModalUnsavedChanges("")}
            />
          </div>,
        ]}
      >
        <div className="popup-text">
          You have pending changes in your connectors. Leaving this page will
          discard these changes
        </div>
      </Modal>
    </>
  );
});
