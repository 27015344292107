import { Cascader } from "antd";
import { toJS } from "mobx";
import { mainAssetKey } from "../stores/PlayerStore";
import { useCallback, useEffect, useState } from "react";
import { findLayersOrLayerAssets } from "@blings/blings-player";

type mapFilterLayerFuncReturn = {
  layerName: string;
  renderText: string;
  layerUid?: number;
  assetId?: string;
  parent?: string;
  child?: string;
};

interface CascadeOption {
  value: string;
  label?: React.ReactNode;
  label_without_number_selected?: React.ReactNode;
  label_to_search?: string;
  label_to_show_on_tag?: React.ReactNode;
  disabled?: boolean;
  children?: CascadeOption[];
  // Determines if this is a leaf node(effective when `loadData` is specified).
  // `false` will force trade TreeNode as a parent node.
  // Show expand icon even if the current node has no children.
  isLeaf?: boolean;
}

interface LayerSelectorProps {
  isMulti: boolean;
  subSelector?: boolean;
  jsonVid?: any;
  placeholder?: string;
  assetLayerPairs;
  selectedAssetId?: string;
  selectedLayerName: string;
  selectedLayerUid?: number;
  selectedAdditionals?: Array<{
    assetId?: string;
    layerName: string;
    layerUid?: number;
  }>;
  onChange;
  index: number;
  mapFilterLayerFunc?: (
    p: Array<mapFilterLayerFuncReturn>,
    layerName: string,
    assetId?: string,
    layerUid?: number,
    additionals?: Array<{
      assetId?: string;
      layerName: string;
      layerUid?: number;
    }>
  ) => Array<mapFilterLayerFuncReturn>;
}

export const LayerSelector = ({
  isMulti,
  placeholder,
  assetLayerPairs,
  selectedAssetId,
  selectedLayerName,
  selectedLayerUid,
  selectedAdditionals,
  jsonVid,
  onChange,
  index,
  subSelector,
  mapFilterLayerFunc, //= ()=> true
}: LayerSelectorProps) => {
  // State declarations
  const [selectedLayers, setSelectedLayers] = useState<any[] | undefined>(
    () => {
      const assetId = selectedAssetId ? selectedAssetId : mainAssetKey;
      let value: string[][] = [];
      if (selectedLayerName) {
        const selectedLayerUidExists = selectedLayerUid !== undefined;
        let layerData: any = undefined;
        if (!selectedLayerUidExists) {
          layerData = findLayersOrLayerAssets({
            jsonVid: jsonVid,
            layerName: selectedLayerName,
            assetId: assetId,
          });
        }
        const layerDataUid = layerData ? layerData[0].uid : undefined;
        const uuid = selectedLayerUid || layerDataUid;
        const hashValue = hashFromAssetIDAndLayerName(
          assetId,
          selectedLayerName,
          uuid
        );
        let _value = JSON.stringify({
          assetId: assetId,
          layerName: selectedLayerName,
          layerUid: uuid,
          hash: {
            value:
              hashValue +
              "_" +
              GetLayerRenderText(
                hashValue,
                assetLayerPairs,
                mapFilterLayerFunc
              ),
          },
        });
        if (assetId !== "main") {
          value.push([assetId, _value]);
        } else value.push([_value]);
      }

      if (selectedAdditionals) {
        selectedAdditionals.forEach((additional) => {
          const assetId = additional.assetId
            ? additional.assetId
            : mainAssetKey;

          const selectedLayerUidExists = additional.layerUid !== undefined;
          let layerData: any = undefined;
          if (!selectedLayerUidExists) {
            layerData = findLayersOrLayerAssets({
              jsonVid: jsonVid,
              layerName: additional.layerName,
              assetId: additional.assetId,
            });
          }
          const layerDataUid = layerData ? layerData[0].uid : undefined;

          const uuid = additional.layerUid || layerDataUid;

          const hashValue = hashFromAssetIDAndLayerName(
            assetId,
            additional.layerName,
            uuid
          );
          const newValue = JSON.stringify({
            assetId: assetId,
            layerName: additional.layerName,
            layerUid: uuid,
            hash: {
              value:
                hashValue +
                "_" +
                GetLayerRenderText(
                  hashValue,
                  assetLayerPairs,
                  mapFilterLayerFunc
                ),
            },
          });
          if (assetId !== "main") {
            value.push([assetId, newValue]);
          } else value.push([newValue]);
        });
      }
      return value;
    }
  );
  const [allCompositions, setAllCompositions] = useState<any[]>([]);
  const [selectableOptions, setSelectableOptions] = useState<CascadeOption[]>(
    []
  );

  // Function declarations
  const handleChange = useCallback(
    (_value) => {
      // The value is a string array but we cant use as a str[] because of antd internals
      const value = _value as any[];
      const changeInput: {
        assetId: string;
        layerName: string;
        layerUid: string;
        additionals: { assetId: string; layerName: string; layerUid: string }[];
      } = { assetId: "", layerName: "", layerUid: "", additionals: [] };
      /**
       * Structure of the value array
       * [
       *    [selectedOptionValue], // When a option is selected in the root
       *    [selectedComp, selectedOptionValue], // When a option is selected as the child of a composition
       *    [selectedComp] // When all options of that composition are selected
       * ]
       */
      if (value.length === 0)
        onChange(index, undefined, undefined, undefined, undefined);
      else {
        // For the first value, set it as the main asset
        if (value[0].length) {
          let aux, assetId, layerName, layerUid, hash;
          try {
            if (value[0].length === 1) {
              aux = JSON.parse(value[0] || "{}");
            } else {
              aux = JSON.parse(value[0][1] || "{}");
            }
          } catch (e) {
            //BIO-2422: FIX - THIS IS FIX FOR WHEN SELECTING INPUT THAT IS SEND FROM THE CASCADER AS A STRING. THE FIRST IF WILL NOT MATCH SINCE IT'S A STRING AND IT WILL GET THE NUMBER OF CHARACTERS AND WILL GO TO THE 2° IF WHERE IT WILL THROW AN ERROR. THIS ERROR MEANS THAT THE VALUE IS A STRING AND NOT AN ARRAY
            aux = JSON.parse(value[0] || "{}");
          }
          assetId = aux.assetId === mainAssetKey ? undefined : aux.assetId;
          layerName = aux.layerName;
          layerUid = aux.layerUid;
          hash = aux.hash;
          changeInput.assetId = assetId;
          changeInput.layerName = layerName;
          changeInput.layerUid = layerUid;
        }

        for (let i = 1; i < value.length; i++) {
          let aux;
          if (value[i].length === 1) {
            aux = value[i];
          } else {
            aux = value[i][1];
          }
          const optionValue = aux;
          const { assetId, layerName, layerUid, hash } = JSON.parse(
            optionValue
          );
          changeInput.additionals.push({
            assetId: assetId === mainAssetKey ? undefined : assetId,
            layerName,
            layerUid,
          });
        }
        onChange(
          index,
          changeInput.assetId,
          changeInput.layerName,
          changeInput.layerUid,
          changeInput.additionals
        );
      }

      const selectedCompositionsArr = value.filter((v) => {
        if (v.length === 2) return true;
      });
      const selectedCompositionCount = {};
      for (let i = 0; i < selectedCompositionsArr.length; i++) {
        const composition = selectedCompositionsArr[i][0];
        if (selectedCompositionCount[composition]) {
          selectedCompositionCount[composition] += 1;
        } else {
          selectedCompositionCount[composition] = 1;
        }
      }
      setSelectableOptions((prev) => {
        return prev.map((option) => {
          if (option.value in selectedCompositionCount) {
            return {
              ...option,
              label: (
                <>
                  {option.label_without_number_selected}{" "}
                  <span style={{ color: "rgba(245, 34, 45, 1)" }}>
                    ({selectedCompositionCount[option.value]})
                  </span>
                </>
              ),
            };
          } else {
            return {
              ...option,
              label: option.label_without_number_selected,
            };
          }
        });
      });

      setSelectedLayers(value);
    },
    [onChange]
  );

  // useEffect declarations
  useEffect(() => {
    // Set all compositions
    setAllCompositions(Array.from(toJS<[string, any]>(assetLayerPairs)));
  }, [assetLayerPairs]);

  useEffect(() => {
    // Set the relevant compositions
    const obj: { [key: string]: CascadeOption } = {};
    const _selectableOptions: CascadeOption[] = [];
    allCompositions.flatMap(([assetId, layers]) => {
      if (mapFilterLayerFunc) {
        const reducedLayers = layers.reduce(
          (p, c) => mapFilterLayerFunc(p, c.nm, assetId, c?.uid, []),
          []
        );
        // Set the selectable options. This will be used to populate the cascader
        reducedLayers.map((layerNameOrTextRender) => {
          const data = layerNameOrTextRender as mapFilterLayerFuncReturn;
          const parent = data.parent as string;
          const child = data.child;
          // const assetId = data.additional.assetId ? additional.assetId : mainAssetKey;
          const optionValue = JSON.stringify({
            assetId: assetId,
            layerName: data.layerName,
            layerUid: data.layerUid,
            hash: {
              value:
                hashFromAssetIDAndLayerName(
                  assetId,
                  data.layerName,
                  data.layerUid
                ) +
                "_" +
                data.renderText,
            },
          });
          if (!Object.keys(obj).includes(parent)) {
            obj[parent] = {
              value: optionValue,
              children: [],
              label: parent,
              label_to_search: parent,
              label_without_number_selected: parent,
            };
          } else if (!child) {
            obj[`${parent}+ id:${data.layerUid}`] = {
              // if two layers with same name but different id
              value: optionValue,
              children: [],
              label: parent,
              label_to_search: parent,
              label_without_number_selected: parent,
            };
          }
          if (child) {
            obj[parent].value = assetId;
            obj[parent].children?.push({
              value: optionValue,
              label_to_search: child,
              label: child,
              label_to_show_on_tag: (
                <span title={child + " / " + assetId}>
                  {child}{" "}
                  <span style={{ color: "rgba(92, 92, 92, 1)" }}>
                    {" "}
                    / {assetId}
                  </span>
                </span>
              ),
              // child + ,
              label_without_number_selected: child,
              children: [],
            });
          }
        });
      } else {
        layers.map((layerNameOrTextRender, layerIndex) => {
          const layerName = layerNameOrTextRender.nm;
          const layerUid = layerNameOrTextRender?.uid;
          const text = `${layerName}`;

          const optionValue = JSON.stringify({
            assetId,
            layerName,
            layerUid,
            hash: {
              value:
                hashFromAssetIDAndLayerName(assetId, layerName, layerUid) +
                "_" +
                text +
                " - " +
                assetId,
            },
          });

          if (assetId !== mainAssetKey) {
            if (!Object.keys(obj).includes(assetId)) {
              obj[assetId] = {
                value: optionValue,
                children: [],
                label: assetId,
                label_to_search: assetId,
                label_without_number_selected: assetId,
              };
            }
            if (
              obj[assetId].children?.findIndex(
                (child) => child.value === optionValue
              ) === -1
            ) {
              obj[assetId].children?.push({
                value: optionValue,
                label_to_search: text,
                label: text,
                label_to_show_on_tag: (
                  <span title={text + " / " + assetId}>
                    {text}{" "}
                    <span style={{ color: "rgba(92, 92, 92, 1)" }}>
                      {" "}
                      / {assetId}
                    </span>
                  </span>
                ),
                label_without_number_selected: text,
                children: [],
              });
              obj[assetId].value = assetId;
            }
          } else {
            const key = `${layerName} - ${assetId}`;
            if (
              !Object.values(obj).some((item) => item.value === optionValue)
            ) {
              obj[key] = {
                value: optionValue,
                label: text,
                label_to_search: text,
                label_without_number_selected: text,
                children: [],
              };
            }
          }
        });
      }
    });
    const selectedCompositionCount = {};
    if (selectedLayers && selectedLayers.length > 0) {
      const selectedCompositionsArr = selectedLayers.filter((v) => {
        if (v.length === 2) return true;
      });
      for (let i = 0; i < selectedCompositionsArr.length; i++) {
        const composition = selectedCompositionsArr[i][0];
        if (selectedCompositionCount[composition]) {
          selectedCompositionCount[composition] += 1;
        } else {
          selectedCompositionCount[composition] = 1;
        }
      }
    }

    for (const key in obj) {
      if (Object.keys(selectedCompositionCount).includes(key)) {
        obj[key].label = (
          <>
            {obj[key].label_without_number_selected}{" "}
            <span style={{ color: "rgba(245, 34, 45, 1)" }}>
              ({selectedCompositionCount[obj[key].value]})
            </span>
          </>
        );
      }
      _selectableOptions.push(obj[key]);
    }
    setSelectableOptions(_selectableOptions);
  }, [allCompositions]);

  // Extra logic
  function displayRender(label, selectedOptions) {
    if (!selectedOptions[selectedOptions.length - 1]) {
      return <p title={label}>{label}</p>;
    }
    return (
      <p>
        {selectedOptions[selectedOptions.length - 1].label_to_show_on_tag
          ? selectedOptions[selectedOptions.length - 1].label_to_show_on_tag
          : selectedOptions[selectedOptions.length - 1]
              .label_without_number_selected}
      </p>
    );
  }

  function customSearch(
    inputValue: string,
    options: CascadeOption[],
    fieldNames
  ) {
    for (let i = 0; i < options.length; i++) {
      if (
        options[i].label_to_search
          ?.toLowerCase()
          .includes(inputValue.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  }

  function renderSearch(inputValue, path, prefixCls, fieldNames) {
    const jsxToRender: JSX.Element[] = [];

    let renderText = "";
    for (let i = 0; i < path.length; i++) {
      renderText += path[i].label_to_search + " / ";
    }
    renderText = renderText.slice(0, renderText.length - 3);

    // for (let i = 0; i < path.length; i++) {
    const matchingText = inputValue;
    const matchingTextStartIndex = renderText
      .toLowerCase()
      .indexOf(inputValue.toLowerCase());
    const matchingTextEndIndex = matchingTextStartIndex + inputValue.length;
    const textBeforeMatchingText = renderText.slice(0, matchingTextStartIndex);
    const textAfterMatchingText = renderText.slice(matchingTextEndIndex);
    jsxToRender.push(
      <>
        {textBeforeMatchingText}
        <span style={{ color: "rgba(255, 92, 92, 1)" }}>{matchingText}</span>
        {textAfterMatchingText}
      </>
    );
    // }
    return jsxToRender;
  }

  return (
    <Cascader
      size="large"
      style={{ width: "100%" }}
      options={selectableOptions}
      onChange={(a) => {
        !isMulti ? handleChange([a]) : handleChange(a);
      }}
      dropdownClassName={subSelector ? "subSelector" : ""}
      expandTrigger="hover"
      // maxTagCount="responsive"
      multiple={isMulti}
      // dropdownMatchSelectWidth={true}
      showCheckedStrategy="SHOW_CHILD"
      placeholder={placeholder || isMulti ? "Choose layers" : "Choose layer"}
      showSearch={{
        filter: customSearch,
        render: renderSearch,
      }}
      displayRender={displayRender}
      value={
        selectedLayers && selectedLayers.length > 0 ? selectedLayers : undefined
      }
    />
  );
};

/**
 * Get the render text for a specific hash value
 * @param hashValue Receives the hash value of the layer
 * @returns Returns the render text of the layer
 */
function GetLayerRenderText(
  hashValue: number,
  assetLayerPairs: any,
  mapFilterLayerFunc: any
): string {
  let result = "";
  Array.from(toJS<[string, any]>(assetLayerPairs)).flatMap(
    (
      [assetId, layers]: [
        string,
        { nm: string; uid: number | undefined }[],
        Array<{
          assetId?: string;
          layerName?: string;
        }>
      ],
      assetIndex
    ) =>
      mapFilterLayerFunc
        ? layers
            .reduce<mapFilterLayerFuncReturn[]>(
              (p, c) => mapFilterLayerFunc(p, c.nm, assetId, c?.uid, []),
              []
            )
            .map((layerNameOrTextRender, layerIndex) => {
              const layerName = (layerNameOrTextRender as mapFilterLayerFuncReturn)
                .layerName;

              const text = (layerNameOrTextRender as mapFilterLayerFuncReturn)
                .renderText;
              const layerUid = (layerNameOrTextRender as mapFilterLayerFuncReturn)
                .layerUid;
              if (
                hashValue ===
                hashFromAssetIDAndLayerName(assetId, layerName, layerUid)
              ) {
                result = text;
                return text;
              }
              return "";
            })
        : layers.map((layerNameOrTextRender, layerIndex) => {
            const layerName = layerNameOrTextRender.nm;
            const layerUid = layerNameOrTextRender.uid;
            const text = `${layerName} - ${assetId}`;
            if (
              hashValue ===
              hashFromAssetIDAndLayerName(assetId, layerName, layerUid)
            ) {
              result = text;
              return text;
            }
            return "";
          })
  );
  return result;
}

/**
 * Create a random hash using the asset id and layer name as the seeds
 * @param assetId The asset id to use in the hash function
 * @param layerName The layer name to use in the hash function
 * @returns The hash of the asset id and layer name
 */
export function hashFromAssetIDAndLayerName(
  assetId,
  layerName,
  layerUid?: number
) {
  let hash = 0;
  if (assetId.length === 0) return hash;
  for (let i = 0, j = 0; j < assetId.length; i++, j++) {
    let char = assetId.charCodeAt(i);

    // If the char at the i index does not exist, then we have reached the end of the string and should start over
    if (char === undefined) {
      i = 0;
      char = assetId.charCodeAt(i);
    }
    // Create the hash
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }

  if (layerName.length === 0) return hash;
  for (let i = 0, j = 0; j < layerName.length; i++, j++) {
    let char = layerName.charCodeAt(i);

    // If the char at the i index does not exist, then we have reached the end of the string and should start over
    if (char === undefined) {
      i = 0;
      char = layerName.charCodeAt(i);
    }

    // Create the hash
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  let layerUidString = layerUid?.toLocaleString() || "";
  if (layerUidString?.length === 0) return hash;

  for (let i = 0, j = 0; j < layerUidString.length; i++, j++) {
    let char = layerUidString.charCodeAt(i);

    if (char === undefined) {
      i = 0;
      char = layerUidString.charCodeAt(i);
    }

    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32-bit integer
  }

  return hash;
}
