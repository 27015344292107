import { IInteractiveModJSExpression } from "@blings/blings-player";
import { ModsPanelCompProps } from "../../ModsPanel";
import { AddInteractiveHOC } from "./InteractiveFormHOC";
import { JsEditor } from "../../../../components/Editor/JsEditor";
import Text from "antd/lib/typography/Text";
import { Col, Input, Row } from "antd";
import { useMst } from "../../../../stores/main";
import MainCTAButton from "../../../MainCTA/MainCTAButton";
import { ExperimentOptimizationTechnique } from "../../../../API";

export const useDefaultFunctionForInteractionExpression = (
  isControlDeprecated: boolean = false
) => `(data, ${
  isControlDeprecated ? "_" : "liveData"
}, event, element, player) => {
  console.log("clicked") 
}`;

function RenderActionForm(
  props: ModsPanelCompProps<IInteractiveModJSExpression>
) {
  const {
    platformStore,
    experimentStore: { selectedExperimentOptimizationTechnique },
  } = useMst();
  const defaultString = useDefaultFunctionForInteractionExpression(
    platformStore.isControlDeprecated
  );
  const hasVariantsEnabled =
    selectedExperimentOptimizationTechnique &&
    selectedExperimentOptimizationTechnique !==
      ExperimentOptimizationTechnique.DISABLED;
  return (
    <>
      <Row>
        <Col>
          <Text type="secondary">Give this button a name [Optional]</Text>
          {/* <InfoTooltip info="" /> */}
          <div style={{ display: "flex", alignItems: "center", gap: "25px" }}>
            <Input
              size={"large"}
              id="connector-cta-name"
              value={props.change.ctaName}
              addonBefore={"Name"}
              // eslint-disable-next-line no-template-curly-in-string
              placeholder="Example: Use the text on the button"
              onChange={(e) =>
                props.onChange(props.index, ["ctaName"], [e.target.value])
              }
              allowClear={true}
            />
          </div>
        </Col>
      </Row>
      <JsEditor
        defaultValue={
          props.change.functionString === ""
            ? defaultString
            : props.change.functionString
        }
        onChange={(e: any) => props.onChange(props.index, "functionString", e)}
        tooltipText="This function will run every time the user interacts with the layer\s"
      />
      {hasVariantsEnabled && (
        <MainCTAButton
          change={props.change}
          onChange={props.onChange}
          index={props.index}
        />
      )}
    </>
  );
}

export function AddInteractionJsExpressionForm(
  props: ModsPanelCompProps<IInteractiveModJSExpression>
) {
  return (
    <AddInteractiveHOC<IInteractiveModJSExpression>
      {...props}
      renderSpecificInteractive={RenderActionForm}
    />
  );
}
