import { ModsPanelCompProps } from "../ModsPanel";
import { IAdvancedConfigMod } from "@blings/blings-player";
import { Space, Switch, Typography } from "antd";

export function ConfigModForm(props: ModsPanelCompProps<IAdvancedConfigMod>) {
  const { change } = props;
  return (
    <Space direction={"vertical"}>
      <Space direction={"vertical"}>
        <Space>
          Automatically change RTL direction :{" "}
          <Switch
            onChange={(checked) => {
              props.onChange(props.index, "autoRTL", checked);
            }}
            checked={change.autoRTL}
          />
        </Space>
        <Typography.Text type="secondary">
          Note: might not work perfectly for English and RTL mixture
        </Typography.Text>
      </Space>

      <Space direction={"vertical"}>
        <Space>
          Fix Arabic letters not connected :{" "}
          <Switch
            onChange={(checked) => {
              props.onChange(props.index, "fixArabic", checked);
            }}
            checked={change.fixArabic}
          />
        </Space>
        <Typography.Text type="secondary">
          Note: Removes text animation and letter spacing. Does not work on
          paragraph text!
        </Typography.Text>
      </Space>
    </Space>
  );
}
