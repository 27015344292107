import React, { useCallback, useEffect } from "react";
import { QRCode, Skeleton, Typography } from "antd";
import {
  setUpTOTP,
  verifyTOTPSetup,
  updateMFAPreference,
} from "aws-amplify/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getENV } from "../../stores/consts";
import { useMst } from "../../stores/main";
import { BlingsButton } from "../BlingsButton/BlingsButton";

const { Title, Text, Paragraph } = Typography;

const withSetupTOTPMFA = (WrappedComponent: React.ComponentType<any>) => {
  return (props: any) => {
    // State declaration
    const { accountStore } = useMst();
    const [needToSetTOTP, setNeedToSetTOTP] = React.useState<boolean>(false);

    const [QRCodeValue, setQRCodeValue] = React.useState<string | null>(null);
    const [confirmationCount, setConfirmationCount] = React.useState<number>(0);
    const [MFAEnabled, setMFAEnabled] = React.useState<boolean>(false);
    const [MFAMethod, setMFAMethod] = React.useState<string | null>(null);

    // Function Declaration
    const checkMFA = useCallback(async () => {
      // Await for the account store to load
      accountStore.addFunctionToLoadCallback(() => {
        const mfaEnabled = accountStore.MFAEnabled;
        const mfaRequired = accountStore.MFARequired;
        if (mfaRequired && !mfaEnabled) {
          setNeedToSetTOTP(true);
          setupTOTP();
        }
      });
    }, [accountStore]);

    const setupTOTP = async () => {
      try {
        const userEmail = accountStore.userAttributes?.email;
        const appName = getENV() === "master" ? "Blings" : "Blings: dev";
        const totpSetupDetails = await setUpTOTP();
        const setupUri = totpSetupDetails.getSetupUri(appName, userEmail);
        setQRCodeValue(setupUri.toString());
      } catch (err) {
        console.error(err);
      }
    };

    const verifyTOTP = async (token: string) => {
      try {
        // const user = await Auth.currentAuthenticatedUser();
        await verifyTOTPSetup({ code: token });
    
        // Increment confirmation count
        setConfirmationCount((prevCount) => prevCount + 1);
        // // If confirmed twice, set user's preferred MFA method to TOTP
        if (confirmationCount + 1 >= 2) {
          await updateMFAPreference({ totp: "PREFERRED" });
          setNeedToSetTOTP(false);
          setQRCodeValue(null);
          toast.success("MFA auth enabled!");
        } else {
          toast.success(
            "First confirmation successful! Please confirm once more."
          );
        }
      } catch (_err: unknown) {
        const err = (_err as Error).toString();
        console.error(err);
        if (err.includes("InvalidParameterException")) {
          toast.error("Invalid token. The maximum length is 6.");
        } else if (err.includes("Code mismatch")) {
          toast.error("Invalid token. Please try again.");
        } else if (err.includes("EnableSoftwareTokenMFAException")) {
          toast.error("Invalid token. This token has already been used once.");
        }
      }
    };

    // Effect Declaration
    useEffect(() => {
      checkMFA();
    }, [checkMFA]);

    useEffect(() => {
      if (needToSetTOTP) setupTOTP();
    }, [needToSetTOTP]);

    // Pass the state and setters as props to the wrapped component
    if (!needToSetTOTP) {
      return <WrappedComponent {...props} />;
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "white",
          }}
        >
          <ToastContainer
            position="bottom-left"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <Paragraph>
            <Title level={4}>Authenticator based MFA</Title>
            <Text>
              Your account setting is set to require Authenticator based MFA.
              Please follow the steps below to setup MFA.
            </Text>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <button onClick={setupTOTP}>Setup TOTP</button> */}
              {QRCodeValue ? (
                <div>
                  <QRCode value={QRCodeValue} size={256} />
                  <input
                    type="text"
                    id="totpToken"
                    placeholder="Enter TOTP token"
                  />
                  <BlingsButton
                    onClick={() => {
                      const token = (document.getElementById(
                        "totpToken"
                      ) as HTMLInputElement).value;
                      verifyTOTP(token);
                    }}
                  >
                    Confirm TOTP Token
                  </BlingsButton>
                </div>
              ) : (
                <Skeleton.Image active={QRCodeValue !== undefined} />
              )}
            </div>
          </Paragraph>
        </div>
      );
    }
  };
};

export default withSetupTOTPMFA;
