import { Project, ProjectVersion, UpdateProjectVersionInput } from "../API";
import {
  createProjectVersion,
  updateProject,
  updateProjectVersion,
} from "../graphql/mutations";
import { insertIntoGraphQlString } from "modifygraphqlstring";
import { getProject, getProjectVersion } from "../graphql/queries";
import Observable from "zen-observable";
import { ProjectBasicInfo } from "../stores/PlatformStore";
import { generateClient } from "aws-amplify/api";

const client = generateClient();

export async function createVersion(project: Project | ProjectVersion) {
  const input = {
    id: project.id,
    accountOwner: isProjectVersion(project)
      ? project.accountOwner
      : project.account.id,
    settings: project.settings,
    settingsJsonSchemaStr: project.settingsJsonSchemaStr,
    stateJsonSchemaStr: project.stateJsonSchemaStr,
    videoParts: project.videoParts?.map((part) => {
      const { __typename, ...rest } = part;
      return rest;
    }),
  };

  const modifiedCreateProjectVersion = insertIntoGraphQlString(
    insertIntoGraphQlString(createProjectVersion, {
      path: ["videoParts"],
      key: "modsArr",
      value: {
        id: true,
        dataStr: true,
        name: true,
      },
    }),
    {
      path: ["videoParts"],
      key: "fonts",
      value: {
        family: true,
        style: true,
        url: true,
        weight: true,
      },
    }
  );
  const {
    data: { createProjectVersion: projectVersion },
  } = (await client.graphql({
    query: modifiedCreateProjectVersion,
    variables: {
      input,
    },
  })) as { data: { createProjectVersion: ProjectVersion } };
  return projectVersion;
}

export async function updateVersion(project: UpdateProjectVersionInput) {
  const input = {
    id: project.id,
    settings: project.settings,
    settingsJsonSchemaStr: project.settingsJsonSchemaStr,
    stateJsonSchemaStr: project.stateJsonSchemaStr,
    videoParts: project.videoParts,
  };
  const response = await client.graphql({
    query: updateProjectVersion,
    variables: {
      input,
    },
  });
  return response.data.updateProjectVersion as ProjectVersion;
}
export async function publishVersion(projectVersion: ProjectVersion) {
  const {
    id,
    settings,
    settingsJsonSchemaStr,
    stateJsonSchemaStr,
    videoParts,
    playerVersionToUse,
    experiments
  } = projectVersion;
  const input = {
    id,
    settings,
    settingsJsonSchemaStr,
    stateJsonSchemaStr,
    videoParts,
    playerVersionToUse,
    experiments,
    publishedAt: new Date().toISOString(),
  };
  const modifiedUpdateProject = insertIntoGraphQlString(
    insertIntoGraphQlString(updateProject, {
      path: ["videoParts"],
      key: "modsArr",
      value: {
        id: true,
        dataStr: true,
        origin: true,
        name: true,
      },
    }),
    {
      path: ["videoParts"],
      key: "fonts",
      value: {
        family: true,
        style: true,
        url: true,
        weight: true,
      },
    }
  )
  return (await client.graphql({
    query: modifiedUpdateProject,
    variables: {
      input,
    },
  })) as { data: ProjectVersion };
}
export async function getPublishedProject(id: string) {
  const modifiedGetProject = insertIntoGraphQlString(
    insertIntoGraphQlString(getProject, {
      path: ["videoParts"],
      key: "modsArr",
      value: {
        id: true,
        dataStr: true,
        origin: true,
        name: true,
      },
    }),
    {
      path: ["videoParts"],
      key: "fonts",
      value: {
        family: true,
        style: true,
        url: true,
        weight: true,
      },
    }
  );

  const {
    data: { getProject: project },
  } = (await client.graphql({
    query: modifiedGetProject,
    variables: {
      id,
    },
  })) as { data: { getProject: Project } };
  return project;
}

export async function getPublishedProjectBasicInfo(id: string) {
  const getProject = `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      title
      updatedAt
      publishedAt
      createdAt
      playerVersionToUse
      thumbS3Url
      experimentOptimizationTechnique
    }
  }
`;

  const {
    data: { getProject: project },
  } = (await client.graphql({
    query: getProject,
    variables: {
      id,
    },
  })) as { data: { getProject: ProjectBasicInfo } };
  return project;
}

export async function getLatestProjectVersion(id: string) {
  // TODO add logic for fetching latest version when we have multiple versions per project id
  const modifiedGetProjectVersion = 
    insertIntoGraphQlString(
    insertIntoGraphQlString(
    insertIntoGraphQlString(
    insertIntoGraphQlString(getProjectVersion, {
      path: ["videoParts"],
      key: "modsArr",
      value: {
        id: true,
        dataStr: true,
        origin: true,
        name: true,
      },
    }),
    {
      path: ["videoParts"],
      key: "fonts",
      value: {
        family: true,
        style: true,
        url: true,
        weight: true,
      },
    }
  ),{
    path: ["experiments"],
    key: "variants",
    value: {
      id: true,
      value: true,
    }
  }),
  {
    path: ["experiments", "variants"],
    key: "successFactors",
    value: {
      conversionSuccessData: true,
      engagementSuccessData: true,
      selectionCount: true,
      watchTimeSuccessData: true    
    }
  }
  ).replaceAll("__typename", "");
  const {
    data: { getProjectVersion: projectVersion },
  } = (await client.graphql({
    query: modifiedGetProjectVersion,
    variables: {
      id,
    },
  })) as { data: { getProjectVersion: ProjectVersion } };
  return projectVersion;
}

export async function getProjectChangesObservable(id: string) {
  const subscription = `
  subscription OnUpdateProjectSub($id: ID) {
    onUpdateProjectSub(id: $id) {
      id
      title
      playerVersionToUse
      updatedAt
      createdAt
    }
  }
`;
  const response = await client.graphql({
    query: subscription,
    variables: {
      id,
    },
  });
  if (response instanceof Observable) {
    return response as Observable<object>;
  }
  return null;
}
export async function subscribeToProjectVersionChanges(id: string) {}

function isProjectVersion(
  project: Project | ProjectVersion
): project is ProjectVersion {
  return (project as ProjectVersion).accountOwner !== undefined;
}
